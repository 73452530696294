import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { sanitizeEmail, sanitizePassword } from "./utils.js/sanitize";
import { url } from "./utils.js/endpoint/endpoint";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser } from "../redux/userSlice";

export const FormLogin = () => {
  const [captchaToken, setCaptchaToken] = useState(null); // Estado para el token de reCAPTCHA
  const dispatch = useDispatch()

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!captchaToken) {
      Swal.fire({
        title: '¡Error!',
        text: 'Por favor completa el reCAPTCHA.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    try {
      const email = await sanitizeEmail(event.target.email.value);
      const password = await sanitizePassword(event.target.password.value);

      const res = await fetch(`${url}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, token: captchaToken }), // Utilizamos el token del estado
        credentials: 'include'
      });
      
      const result = await res.json();
      if (result.succes) {
        dispatch(addUser(result.user))
        Swal.fire({
          title: '¡Éxito!',
          text: 'Has iniciado sesión',
          icon: 'success',
          confirmButtonText: 'Aceptar',
        });
        setCaptchaToken(null); // Reseteamos el token
      } else {
        Swal.fire({
          title: '¡Error!',
          text: 'Usuario o contraseña incorrecta.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    } catch (error) {
      Swal.fire({
        title: '¡Error!',
        text: 'Ocurrió un error al procesar la solicitud.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
      console.error('Error al procesar la solicitud:', error);
    }
  };

  const onChange = (value) => {
    setCaptchaToken(value); // Guardamos el token en el estado
  };

  return (
    <div className="container-login">
      <form className="form-log" onSubmit={handleLogin}>
        <h2>Login</h2>
        <div className="input-group">
          <div className="input-group-append">
            <span className="input-group-text"><i className="bi bi-envelope-at"></i></span>
          </div>
          <input type="email" name="email" placeholder="email" className="form-control input_pass" required />
        </div>
        <div className="input-group">
          <div className="input-group-append">
            <span className="input-group-text"><i className="bi bi-key"></i></span>
          </div>
          <input type="password" name="password" placeholder="password" className="form-control input_pass" required />
        </div>
        <div className="recaptcha">
          <ReCAPTCHA
            sitekey="6LcwvjcqAAAAAKXjL0CHiKhPE_cbMMjI-pXx57yZ"
            onChange={onChange} // Cuando el reCAPTCHA devuelve un valor, lo guardamos en el estado
          />
        </div>
        <div className="buttons">
          <button type="submit" className="btn btn-primary btn-form">Ingresar</button>
          <button type="button" className="btn btn-primary btn-aux">
            <Link to={"/register"}>Registrar</Link>
          </button>
        </div>
      </form>
    </div>
  );
};